<template>
    <div class="w-full min-h-[100px] xl:w-1/2" v-loading="$waiting.is('loading.huvudbok')">
        <div class="relative hidden print:block text-center mb-5 pb-4 border-b w-full">
            <p class="absolute top-0 right-0 text-xs">utskriven {{ todayDate }}</p>
            <p>{{ tableData.heading1 }}</p>
            <p>{{ tableData.heading2 }}</p>
        </div>
        <div v-for="item in tableData.getLedgerAccounts" :key="item.account" class="card-tail">
            <p class="border-b pb-2 mb-2">{{ item.account }} {{ item.accountName }}</p>
            <table class="text-sm">
                <tr class="border-b ">
                    <th width="130" class="font-medium pb-1 print:text-xs">Datum</th>
                    <th width="80" class="font-medium print:text-xs">Verifikat</th>
                    <th width="300" class="font-medium print:text-xs">Text</th>
                    <th width="20" />
                    <th width="110" align="right" class="font-medium print:text-xs">Debet</th>
                    <th width="110" align="right" class="font-medium print:text-xs">Kredit</th>
                    <th width="110" align="right" class="font-medium print:text-xs">Saldo</th>
                </tr>
                <tr v-for="trans in item.transactions" :key="trans.id">
                    <td class="py-1 print:text-xs">{{ trans.bookingDate }}</td>
                    <td class="py-1 print:text-xs">{{ trans.number }}</td>
                    <td class="py-1 print:text-xs">{{ trans.text }}</td>
                    <td class="py-1 print:text-xs">{{ trans.correction ? "K" : "" }}</td>
                    <td class="py-1 print:text-xs" align="right">{{ trans.debit | swedishNumberFormat }}</td>
                    <td class="py-1 print:text-xs" align="right">{{ trans.credit | swedishNumberFormat }}</td>
                    <td class="py-1 print:text-xs" align="right">{{ trans.balance | swedishNumberFormat }}</td>
                </tr>
            </table>

            <!-- <div class="flex justify-center">
                <p class="hover:bg-blue-100 cursor-pointer transition text-xs rounded px-4 mt-4">
                    747 entries more... Click to load
                </p>
            </div> -->
        </div>
    </div>
</template>
<script>
import moment from "moment";
import Api from "../lopande.api";

export default {
    data() {
        return {
            tableData: [],
        };
    },

    props: {
        query: {
            type: Object,
            default: () => {},
        },
        clientId: {
            type: String,
            default: "",
        },
        selectedDate: {
            type: Array,
            default: () => [],
        },
    },

    computed: {
        todayDate() {
            return moment().format("YYYY-MM-DD");
        },
    },

    watch: {
        selectedDate: function() {
            this.getAll();
        },
    },

    created() {
        this.getAll();
    },

    methods: {
        async getAll() {
            if (!this.selectedDate.length) return;

            this.$waiting.start("loading.huvudbok");
            try {
                const { startDate, endDate } = this.query;
                let data = await Api.getHuvudbok({ clientId: this.clientId, startDate: startDate, endDate: endDate });
                this.tableData = Object.freeze(data);
            } finally {
                this.$waiting.end("loading.huvudbok");
            }
        },
    },
};
</script>
